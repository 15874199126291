import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TimePicker } from "@mui/x-date-pickers";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  TruckPCEInput,
  TruckPercentInput,
  TurnFactorInput,
  VolumeInput,
} from "../Components/CustomInputs";
import { defaultVolumes, ProjectKeyMap } from "./ProjectHelper";
import { ensureNumber } from "../Util/UtilFuncs";
import NavButtonBar from "./NavButtonBar";
import TimePeriodsTable from "../Components/TimePeriodsTable";
import TooltipIcon from "../Components/TooltipIcon";

const STEP_INDEX = 1;

export default function GlobalInputsPanel(props) {
  React.useEffect(() => {
    props.updateActiveStep(STEP_INDEX);
  });

  const [selectedScenarioIdx, setSelectedScenarioIdx] = React.useState(0);

  // action.direction [NB, SB, EB, WB]
  // movement [left, thru, right, truckPct]
  // value [value]
  function reduceVolumeUpdate(state, action) {
    if (action.direction === "setAll") {
      return action.volumesObj;
    } else {
      return {
        ...state,
        [action.direction]: {
          ...state[action.direction],
          [action.movement]: action.value,
        },
      };
    }
  }
  const [volumes, updateVolumes] = React.useReducer(
    reduceVolumeUpdate,
    null,
    undefined
  );

  const [invalidInputs, setInvalidInputs] = React.useState({
    NB: [false, false, false, false],
    SB: [false, false, false, false],
    EB: [false, false, false, false],
    WB: [false, false, false, false],
  });

  const handleInvalidInputs = (key, index, newBool) => {
    let newBoolArray = invalidInputs;
    newBoolArray[key][index] = newBool;
    setInvalidInputs(newBoolArray);
  };

  const [preventSaveInvalid, setPreventSaveInvalid] = React.useState(false);
  React.useEffect(() => {
    let boolHelper = [false, false, false, false];
    // console.log("render");
    Object.keys(invalidInputs).forEach((key, i) => {
      if (invalidInputs[key].filter((bool) => bool === true).length > 0) {
        boolHelper[i] = true;
      }
    });
    // console.log({ boolHelper });
    boolHelper.filter((bool) => bool === true).length > 0
      ? setPreventSaveInvalid(true)
      : setPreventSaveInvalid(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(invalidInputs)]);

  // console.log("preventSaveInvalid: ", preventSaveInvalid);

  const [scenName, setScenName] = React.useState("");
  const [invalidScenName, setInvalidScenarioName] = React.useState(false); // Helper for validation
  const scenarioNameInputRef = React.useRef(null); // Helper for autofocus on scenario select
  const handleNameChange = (event) => {
    setUnsavedChanges(true);
    setScenName(event.target.value);
  };

  const [startTime, setStartTime] = React.useState(null);
  const handleStartTimeChange = (newValue) => {
    setUnsavedChanges(true);
    setStartTime(newValue);
  };

  function reduceTurnFactorUpdate(state, action) {
    return { ...state, [action.turnFactor]: action.value };
  }
  const [turnFactors, updateTurnFactors] = React.useReducer(
    reduceTurnFactorUpdate,
    props.projectGlobalInputs[ProjectKeyMap.ID_GI_TURN_FACTORS],
    undefined
  );
  const handleFactorChange = (valueKey, value) => {
    updateTurnFactors({ turnFactor: valueKey, value: value });
  };

  const [truckPCE, setTruckPCE] = React.useState(
    ensureNumber(
      props.projectGlobalInputs[ProjectKeyMap.ID_GI_TRUCK_PCE],
      parseFloat
    )
  );

  const [clvLimit, setCLVLimit] = React.useState(
    ensureNumber(
      props.projectGlobalInputs[ProjectKeyMap.ID_GI_CLV_LIMIT],
      parseInt
    )
  );
  const handleCLVLimitChange = (evt) => {
    setCLVLimit(evt.target.value);
  };

  const [unsavedChanges, setUnsavedChanges] = React.useState(false);
  const [invalidFactor, setInvalidFactor] = React.useState([
    false,
    false,
    false,
    false,
  ]);
  const [preventNext, setPreventNext] = React.useState(false);

  const handleInvalidFactor = (index, newBool) => {
    let newBoolArray = invalidFactor;
    newBoolArray[index] = newBool;
    setInvalidFactor(newBoolArray);
    setPreventNext(newBoolArray.filter((bool) => bool === true).length > 0);
  };

  const [
    warningUnsavedChangesDialogVisible,
    setWarningUnsavedChangesDialogVisible,
  ] = React.useState(false);
  const handleUnsavedChangesWarningClose = () => {
    setWarningUnsavedChangesDialogVisible(false);
  };

  const selectScenarioByIndex = React.useCallback(
    (scenarioIndex) => {
      //TODO (Here or following useEffect call - alert user to unsaved changes (including on scenario add/duplicate/delete)
      const maxIndex =
        props.projectGlobalInputs[ProjectKeyMap.ID_GI_VOL_SCENARIOS].length - 1;
      const initScenInfo =
        props.projectGlobalInputs[ProjectKeyMap.ID_GI_VOL_SCENARIOS][
          Math.min(maxIndex, scenarioIndex)
        ];

      const initScenName = initScenInfo[ProjectKeyMap.ID_GI_SCN_NAME];
      const initScenStartTime = new Date();
      try {
        initScenStartTime.setHours(
          parseInt(initScenInfo[ProjectKeyMap.ID_GI_SCN_START].split(":")[0])
        );
        initScenStartTime.setMinutes(
          parseInt(initScenInfo[ProjectKeyMap.ID_GI_SCN_START].split(":")[1])
        );
      } catch (error) {
        initScenStartTime.setHours(6);
        initScenStartTime.setMinutes(0);
      }

      setScenName(initScenName);
      setStartTime(initScenStartTime);
      updateVolumes({
        direction: "setAll",
        volumesObj: initScenInfo[ProjectKeyMap.ID_GI_SCN_VOLUMES],
      });
      setUnsavedChanges(false);
      scenarioNameInputRef.current.focus();
    },
    [props.projectGlobalInputs]
  );

  React.useEffect(() => {
    selectScenarioByIndex(selectedScenarioIdx);
  }, [selectedScenarioIdx, selectScenarioByIndex]);

  const discardUnsavedChanges = React.useCallback(() => {
    selectScenarioByIndex(selectedScenarioIdx);
  }, [selectedScenarioIdx, selectScenarioByIndex]);

  const updateSavedVolumeScenario = React.useCallback(() => {
    // Get list of scenario names, blank "" used for selected scenario to eliminate blank name entry
    const trimmedScenName = scenName.trim();
    const nameList = props.projectGlobalInputs[
      ProjectKeyMap.ID_GI_VOL_SCENARIOS
    ].map((oldScenario, index) =>
      index === selectedScenarioIdx
        ? ""
        : oldScenario[ProjectKeyMap.ID_GI_SCN_NAME]
    );
    if (nameList.indexOf(trimmedScenName) >= 0) {
      setInvalidScenarioName(true);
      return;
    } else {
      setInvalidScenarioName(false);
    }
    const scenStartStr =
      startTime.getHours() +
      ":" +
      (startTime.getMinutes() < 10
        ? "0" + startTime.getMinutes()
        : startTime.getMinutes());
    const scenEndStr =
      startTime.getHours() +
      1 +
      ":" +
      (startTime.getMinutes() < 10
        ? "0" + startTime.getMinutes()
        : startTime.getMinutes());
    const scenarioObj = {
      [ProjectKeyMap.ID_GI_SCN_NAME]: trimmedScenName,
      [ProjectKeyMap.ID_GI_SCN_START]: scenStartStr,
      [ProjectKeyMap.ID_GI_SCN_END]: scenEndStr,
      [ProjectKeyMap.ID_GI_SCN_VOLUMES]: volumes,
    };
    // console.log(scenarioObj);
    const newScenarios = props.projectGlobalInputs[
      ProjectKeyMap.ID_GI_VOL_SCENARIOS
    ].map((oldScenario, index) => {
      if (index === selectedScenarioIdx) {
        return scenarioObj;
      } else {
        return oldScenario;
      }
    });
    // props.assignGlobalInputsToProject(selectedScenarioIdx, scenarioObj, turnFactors, truckPCE, clvLimit);
    props.assignGlobalInputsToProject(
      newScenarios,
      turnFactors,
      truckPCE,
      clvLimit
    );
    setUnsavedChanges(false);
  }, [
    clvLimit,
    props,
    scenName,
    selectedScenarioIdx,
    startTime,
    truckPCE,
    turnFactors,
    volumes,
  ]);

  const handleVolumeChange = (direction, movement, value) => {
    // if value has not changed, return without updating anything
    if (`${volumes[direction][movement]}` === `${value}`) return;

    setUnsavedChanges(true);
    updateVolumes({ direction, movement, value });
  };

  const { suggestedTAF, truckPCE: suggestedTruckPce } =
    props.defaultVjustValues;

  /**
   * handle a "next" click to go to step 3
   */
  const handleNext = () =>
    props.assignGlobalInputsToProject(
      undefined, // do not pass scenario volumes since those are not saved on "next" click
      turnFactors ?? suggestedTAF,
      truckPCE ?? suggestedTruckPce,
      clvLimit
    );

  const addVolumeScenario = React.useCallback(() => {
    if (unsavedChanges) {
      setWarningUnsavedChangesDialogVisible(true);
      return;
    }
    const oldNumScenarios =
      props.projectGlobalInputs[ProjectKeyMap.ID_GI_VOL_SCENARIOS].length;
    let currOffset = 1;
    let newScenarioName = "Scenario " + (oldNumScenarios + currOffset);
    let testScenIndex = 0;
    while (
      testScenIndex <
      props.projectGlobalInputs[ProjectKeyMap.ID_GI_VOL_SCENARIOS].length
    ) {
      if (
        newScenarioName ===
        props.projectGlobalInputs[ProjectKeyMap.ID_GI_VOL_SCENARIOS][
          testScenIndex
        ][ProjectKeyMap.ID_GI_SCN_NAME]
      ) {
        testScenIndex = 0;
        currOffset++;
        newScenarioName = "Scenario " + (oldNumScenarios + currOffset);
      } else {
        testScenIndex++;
      }
    }
    const newScenarioObj = {
      [ProjectKeyMap.ID_GI_SCN_NAME]: newScenarioName,
      [ProjectKeyMap.ID_GI_SCN_START]: "7:00",
      [ProjectKeyMap.ID_GI_SCN_END]: "8:00",
      [ProjectKeyMap.ID_GI_SCN_VOLUMES]: defaultVolumes,
    };
    const newScenarios = props.projectGlobalInputs[
      ProjectKeyMap.ID_GI_VOL_SCENARIOS
    ].concat([newScenarioObj]);
    // props.assignGlobalInputsToProject(selectedScenarioIdx, scenarioObj, turnFactors, truckPCE, clvLimit);
    props.assignGlobalInputsToProject(
      newScenarios,
      turnFactors,
      truckPCE,
      clvLimit
    );
    setUnsavedChanges(false);
  }, [clvLimit, props, truckPCE, turnFactors, unsavedChanges]);

  const deleteVolumeScenarioAt = React.useCallback(
    (index) => {
      if (unsavedChanges) {
        setWarningUnsavedChangesDialogVisible(true);
        return;
      }
      const newScenarios = props.projectGlobalInputs[
        ProjectKeyMap.ID_GI_VOL_SCENARIOS
      ]
        .slice(0, index)
        .concat(
          props.projectGlobalInputs[ProjectKeyMap.ID_GI_VOL_SCENARIOS].slice(
            index + 1
          )
        );
      props.assignGlobalInputsToProject(
        newScenarios,
        turnFactors,
        truckPCE,
        clvLimit
      );
      setUnsavedChanges(false);
      setSelectedScenarioIdx(0);
    },
    [clvLimit, props, truckPCE, turnFactors, unsavedChanges]
  );

  const duplicateVolumeScenario = React.useCallback(
    (index) => {
      if (unsavedChanges) {
        setWarningUnsavedChangesDialogVisible(true);
        return;
      }
      const scenarioToBeCopied =
        props.projectGlobalInputs[ProjectKeyMap.ID_GI_VOL_SCENARIOS][index];
      let origScenName = scenarioToBeCopied[ProjectKeyMap.ID_GI_SCN_NAME];
      if (origScenName.length > 16) {
        origScenName = origScenName.slice(0, 16);
      }
      const existingNameList = props.projectGlobalInputs[
        ProjectKeyMap.ID_GI_VOL_SCENARIOS
      ].map((scenario) => scenario[ProjectKeyMap.ID_GI_SCN_NAME]);
      let notNameFound = true;
      let copyIdx = 1;
      while (notNameFound) {
        if (existingNameList.indexOf(origScenName + " (" + copyIdx + ")") < 0) {
          notNameFound = false;
        } else {
          copyIdx++;
        }
      }
      const duplicateScenarioObj = {
        [ProjectKeyMap.ID_GI_SCN_NAME]: origScenName + " (" + copyIdx + ")",
        [ProjectKeyMap.ID_GI_SCN_START]:
          scenarioToBeCopied[ProjectKeyMap.ID_GI_SCN_START],
        [ProjectKeyMap.ID_GI_SCN_END]:
          scenarioToBeCopied[ProjectKeyMap.ID_GI_SCN_END],
        [ProjectKeyMap.ID_GI_SCN_VOLUMES]:
          scenarioToBeCopied[ProjectKeyMap.ID_GI_SCN_VOLUMES],
      };
      const newScenarios = props.projectGlobalInputs[
        ProjectKeyMap.ID_GI_VOL_SCENARIOS
      ].concat([duplicateScenarioObj]);
      props.assignGlobalInputsToProject(
        newScenarios,
        turnFactors,
        truckPCE,
        clvLimit
      );
      setUnsavedChanges(false);
      setSelectedScenarioIdx(newScenarios.length - 1);
    },
    [clvLimit, props, truckPCE, turnFactors, unsavedChanges]
  );

  const handleInternalExportClick = React.useCallback(() => {
    props.assignGlobalInputsToProject(
      props.projectGlobalInputs[ProjectKeyMap.ID_GI_VOL_SCENARIOS],
      turnFactors,
      truckPCE,
      clvLimit
    );
    props.launchExportDialog();
  }, [clvLimit, props, truckPCE, turnFactors]);

  const handleTableChangeSelectedScenario = React.useCallback(
    (newIndex) => {
      console.log("newIndex: ", newIndex, "unsavedChanges", unsavedChanges);
      if (unsavedChanges) {
        setWarningUnsavedChangesDialogVisible(true);
      } else {
        setSelectedScenarioIdx(newIndex);
      }
    },
    [unsavedChanges]
  );

  const theme = useTheme();

  return (
    <div
      style={{
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingTop: "20px",
        height: "100%",
        position: "relative",
      }}
    >
      <div style={{ height: "calc(100% - 50px)", overflow: "auto" }}>
        <Grid
          container
          spacing={1}
          sx={{ maxHeight: "100%", maxWidth: "1200px" }}
        >
          <Grid item xs={12}>
            <Typography variant="h5">Global Inputs</Typography>
            <Typography variant="body2">
              <em>
                Configure the volume scenarios for the analysis, the turning
                movement adjustment factors, and other global inputs.
              </em>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">A. Traffic Volume Scenarios</Typography>
            <Typography variant="body2">
              <em>
                Create scenarios and enter the traffic volumes (veh/hr) and
                truck percentages for each directional movement.
              </em>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item md={12} lg={4}>
                <TimePeriodsTable
                  // scenarioList={[{name: "AM"}, {name: "PM"}]}
                  // selectedScenarioName={"AM"}
                  scenarioList={
                    props.projectGlobalInputs[ProjectKeyMap.ID_GI_VOL_SCENARIOS]
                  }
                  selectedScenarioIndex={selectedScenarioIdx}
                  setSelectedScenarioIndex={handleTableChangeSelectedScenario}
                  addVolumeScenario={addVolumeScenario}
                  deleteVolumeScenarioAt={deleteVolumeScenarioAt}
                  duplicateVolumeScenario={duplicateVolumeScenario}
                />
              </Grid>
              <Grid item md={12} lg={8}>
                <Paper
                  variant="outlined"
                  sx={{
                    height: "100%",
                    padding: "10px",
                    minWidth: "600px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    container
                    spacing={1}
                    sx={{ minWidth: "600px", maxWidth: "800px" }}
                  >
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item lg={4} xl={6}>
                          <TextField
                            inputRef={scenarioNameInputRef}
                            value={scenName}
                            onChange={handleNameChange}
                            label="Name"
                            size="small"
                            inputProps={{ maxLength: "80" }}
                            error={invalidScenName}
                            InputProps={{
                              endAdornment: (
                                <Tooltip
                                  title={
                                    <span style={{ fontSize: "11pt" }}>
                                      Scenario name cannot be blank or the same
                                      as another existing volume scenario.
                                      Maximum 80 characters.
                                    </span>
                                  }
                                  arrow
                                >
                                  <span style={{ marginLeft: "2px" }}>
                                    <FontAwesomeIcon
                                      icon={"fa-solid fa-question-circle"}
                                      style={{
                                        color: theme.palette.grey[400],
                                      }}
                                    />
                                  </span>
                                </Tooltip>
                              ),
                            }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item lg={4} xl={3}>
                          <TimePicker
                            label="Start Time"
                            minutesStep={5}
                            onChange={(newValue) =>
                              handleStartTimeChange(newValue)
                            }
                            slotProps={{
                              textField: { size: "small", variant: "outlined" },
                            }}
                            size="small"
                            value={startTime}
                          />
                        </Grid>
                        <Grid item lg={4} xl={3}>
                          <Button
                            color="secondary"
                            variant="contained"
                            sx={{ float: "right", color: "white" }}
                            onClick={updateSavedVolumeScenario}
                            disabled={!unsavedChanges || preventSaveInvalid}
                          >
                            Save
                          </Button>
                          <Button
                            color="warning"
                            sx={{ float: "right" }}
                            disabled={!unsavedChanges}
                            onClick={discardUnsavedChanges}
                          >
                            Reset
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="body1" align="center">
                        <b style={{ textDecoration: "underline" }}>Movement</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Grid container spacing={1}>
                        <Grid item xs={3}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-arrow-turn-down-left"
                              flip="vertical"
                              style={{ fontSize: "24pt" }}
                            />
                          </div>
                          <Typography
                            variant="body2"
                            align={"center"}
                            sx={{ width: "100%" }}
                          >
                            U-Turn/Left
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-arrow-up"
                              style={{ fontSize: "24pt" }}
                            />
                          </div>
                          <Typography
                            variant="body2"
                            align={"center"}
                            sx={{ width: "100%" }}
                          >
                            Through
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-arrow-turn-down-left"
                              flip="both"
                              style={{ fontSize: "24pt" }}
                            />
                          </div>
                          <Typography
                            variant="body2"
                            align={"center"}
                            sx={{ width: "100%" }}
                          >
                            Right Turn
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-truck"
                              style={{ fontSize: "24pt", margin: "auto" }}
                            />
                          </div>
                          <Typography
                            variant="body2"
                            align={"center"}
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            Truck Percent
                            <TooltipIcon
                              title={
                                "Truck percentage values are used to adjust turning movement volumes to equivalent passenger cars."
                              }
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {volumes !== null &&
                      Object.entries(volumes).map(([key, value]) => (
                        <React.Fragment key={"vol-row-" + key}>
                          <Grid
                            item
                            xs={2}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body1" align="center">
                              <b>{value.label}</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={10}>
                            <Grid container spacing={1}>
                              <Grid item xs={3}>
                                <VolumeInput
                                  value={value.left}
                                  handleChange={(evt) => {
                                    // console.log(
                                    //   parseInt(evt.target.value) < 0 ||
                                    //     isNaN(parseInt(evt.target.value))
                                    // );
                                    handleVolumeChange(
                                      key,
                                      "left",
                                      evt.target.value
                                    );
                                    handleInvalidInputs(
                                      key,
                                      0,
                                      parseInt(evt.target.value) < 0 ||
                                        isNaN(parseInt(evt.target.value))
                                    );
                                  }}
                                  name={`vol-input-${key}`}
                                  id={`vol-input-${key}-left`}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <VolumeInput
                                  value={value.thru}
                                  handleChange={(evt) => {
                                    handleVolumeChange(
                                      key,
                                      "thru",
                                      evt.target.value
                                    );
                                    handleInvalidInputs(
                                      key,
                                      1,
                                      parseInt(evt.target.value) < 0 ||
                                        isNaN(parseInt(evt.target.value))
                                    );
                                  }}
                                  name={`vol-input-${key}`}
                                  id={`vol-input-${key}-thru`}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <VolumeInput
                                  value={value.right}
                                  handleChange={(evt) => {
                                    handleVolumeChange(
                                      key,
                                      "right",
                                      evt.target.value
                                    );
                                    handleInvalidInputs(
                                      key,
                                      2,
                                      parseInt(evt.target.value) < 0 ||
                                        isNaN(parseInt(evt.target.value))
                                    );
                                  }}
                                  name={`vol-input-${key}`}
                                  id={`vol-input-${key}-right`}
                                />
                              </Grid>
                              <Grid item xs={3} sx={{ display: "flex" }}>
                                <Divider
                                  orientation="vertical"
                                  style={{ position: "relative", left: "-4px" }}
                                />
                                <TruckPercentInput
                                  value={value.truckPct}
                                  handleChange={(evt) => {
                                    handleVolumeChange(
                                      key,
                                      "truckPct",
                                      evt.target.value
                                    );
                                    handleInvalidInputs(
                                      key,
                                      3,
                                      isNaN(parseInt(evt.target.value))
                                    );
                                  }}
                                  name={`vol-input-${key}`}
                                  id={`vol-input-${key}-truck-pct`}
                                  error={value.truckPct === ""}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ))}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "10px" }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div style={{ display: "flex", width: "100%" }}>
                  <div style={{ minWidth: "250px", maxWidth: "250px" }}>
                    <Typography variant="h6" sx={{ display: "flex" }}>
                      B. Adjustment Factors
                      <TooltipIcon
                        iconFontSize={"11pt"}
                        title={<AdjustmentFactorDescription />}
                      />
                    </Typography>
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <TurnFactorInput
                          value={turnFactors?.UTAF ?? suggestedTAF.UTAF}
                          label="UTAF"
                          handleChange={(evt) => {
                            handleFactorChange("UTAF", evt.target.value);
                            handleInvalidFactor(
                              0,
                              isNaN(parseInt(evt.target.value))
                            );
                          }}
                          name="turn-factor-input-utaf"
                          id="turn-factor-input-utaf"
                          error={turnFactors?.UTAF === ""}
                          handleReset={(evt) => {
                            handleFactorChange("UTAF", suggestedTAF.UTAF);
                          }}
                          constValue={suggestedTAF.UTAF}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TurnFactorInput
                          value={turnFactors?.LTAF ?? suggestedTAF.LTAF}
                          label="LTAF"
                          handleChange={(evt) => {
                            handleFactorChange("LTAF", evt.target.value);
                            handleInvalidFactor(
                              1,
                              isNaN(parseInt(evt.target.value))
                            );
                          }}
                          name="turn-factor-input-ltaf"
                          id="turn-factor-input-ltaf"
                          error={turnFactors?.LTAF === ""}
                          handleReset={(evt) => {
                            handleFactorChange("LTAF", suggestedTAF.LTAF);
                          }}
                          constValue={suggestedTAF.LTAF}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TurnFactorInput
                          value={turnFactors?.RTAF ?? suggestedTAF.RTAF}
                          label="RTAF"
                          handleChange={(evt) => {
                            handleFactorChange("RTAF", evt.target.value);
                            handleInvalidFactor(
                              2,
                              isNaN(parseInt(evt.target.value))
                            );
                          }}
                          name="turn-factor-input-rtaf"
                          id="turn-factor-input-rtaf"
                          error={turnFactors?.RTAF === ""}
                          handleReset={(evt) => {
                            handleFactorChange("RTAF", suggestedTAF.RTAF);
                          }}
                          constValue={suggestedTAF.RTAF}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <TruckPCEInput
                          value={truckPCE ?? suggestedTruckPce}
                          label={"Truck PCE"}
                          handleChange={(evt) => {
                            setTruckPCE(evt.target.value);
                            handleInvalidFactor(
                              3,
                              isNaN(parseInt(evt.target.value))
                            );
                          }}
                          name={"turn-factor-input-truckPCE"}
                          id={`turn-factor-input-truckPCE`}
                          handleReset={(evt) => {
                            setTruckPCE(suggestedTruckPce);
                          }}
                          constValue={suggestedTruckPce}
                        />
                        <TooltipIcon
                          iconFontSize={"11pt"}
                          title={<TruckPercentDescription />}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <div style={{ display: "flex", height: "100%" }}>
                  <div style={{ minWidth: "250px", maxWidth: "250px" }}>
                    <Typography variant="h6">
                      C. Critical Lane Volume
                    </Typography>
                  </div>
                  <div style={{ minWidth: "250px", maxWidth: "250px" }}>
                    <TextField
                      variant="outlined"
                      value={clvLimit}
                      label="CLV Sum Limit"
                      size={"small"}
                      onChange={handleCLVLimitChange}
                      disabled
                      fullWidth
                    />
                  </div>
                  <div
                    style={{ flexGrow: 1, paddingLeft: "10px", height: "100%" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <Typography variant="body2" style={{ display: "flex" }}>
                        <em>
                          Saturation value for critical lane volume sum at an
                          intersection (Default=1600)
                        </em>
                        <TooltipIcon
                          title={
                            "Future Expanded Description and Guidance for Critical Lane Volume"
                          }
                          iconFontSize={"11pt"}
                        />
                      </Typography>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <NavButtonBar
        stepIndex={STEP_INDEX}
        handleNext={handleNext}
        launchExportDialog={handleInternalExportClick}
        disableNext={unsavedChanges || preventNext}
        nextButtonDisabledTooltip={
          <span style={{ fontSize: "11pt" }}>
            Save or discard any unsaved changes before proceeding to next step.
          </span>
        }
        disableSave={unsavedChanges}
        exportButtonDisabledTooltip={
          <span style={{ fontSize: "11pt" }}>
            Unsaved changes for the current volume scenario must be saved before
            the analysis can be exported.
          </span>
        }
      />
      <Dialog
        open={warningUnsavedChangesDialogVisible}
        onClose={handleUnsavedChangesWarningClose}
        aria-labelledby="unsaved-changes-dialog-title"
        aria-describedby="unsaved-changes-dialog-description"
      >
        <DialogTitle id="unsaved-changes-dialog-title">
          Warning: Unsaved Changes
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="unsaved-changes-dialog-description">
            The volume scenario currently being edited has unsaved changes.
            Please save or discard changes before changing volume scenarios.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUnsavedChangesWarningClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const AdjustmentFactorDescription = () => (
  <span>
    <b style={{ textDecoration: "underline" }}>
      Turning Movement Adjustment Factors:
    </b>
    <br />
    Factors used to convert turning vehicles to equivalent through vehicles.
    <ul>
      <li>
        <b>RTAF</b> - <em>Right Turn Adjustment Factor</em>, suggested value =
        0.85
      </li>
      <li>
        <b>LTAF</b> - <em>Left Turn Adjustment Factor</em>, suggested value =
        0.95
      </li>
      <li>
        <b>UTAF</b> - <em>U-Turn Adjustment Factor</em>, suggested value = 0.8
      </li>
    </ul>
  </span>
);
const TruckPercentDescription = () => (
  <span>
    <b style={{ textDecoration: "underline" }}>
      Truck Passenger Car Equivalent (PCE):
    </b>
    <br />
    Factor to convert trucks to passenger car equivalents.
    <br />
    <br />
    <em>1 Truck = X Passenger Car Equivalents</em>, suggested value = 2.00
  </span>
);

GlobalInputsPanel.propTypes = {
  assignGlobalInputsToProject: PropTypes.func.isRequired,
  defaultVjustValues: PropTypes.object.isRequired,
  launchExportDialog: PropTypes.func.isRequired,
  projectGlobalInputs: PropTypes.object.isRequired,
  updateActiveStep: PropTypes.func.isRequired,
};
